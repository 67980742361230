import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { OrderViewMapContainer } from './style';
import L from 'leaflet';
import { Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import { useSelector } from 'react-redux';
import { selectIsMapOpen, selectMapState } from '../../store/app/appSlice';
import useGetCurrentDriversQuery from '../../core/api/hooks/query/orders/useGetCurrentDriversQuery';
import { pick } from 'lodash';
import { useLocation } from 'react-router';
import {
    OrderViewMapActionsButton,
    OrderViewMapBottomLeftActionsContainer, OrderViewMapBottomRightActionsContainer
} from '../Orders/components/views/OrderViewMap/style';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import moment from 'moment/moment';

const CustomMarker = ({ marker }: any) => {
    const icon = useMemo(() => {
        const type = marker.vehicleType === 2 ? 'circle' : marker.vehicleType === 1 ? 'triangle' : '';
        const color = marker.driverOnDuty ? 'purple' :marker.isFirstCompleted ? 'red' : marker.status === 3 ? 'yellow' : 'green';
        return L.divIcon({
            className: 'custom-icon',
            html: `<div class='custom-marker-icon ${type === 'triangle' ? 'start' : ''}'>
             <div class='marker-icon ${type} ${color}'></div>
             <div class='marker-icon-label ${type} ${color}'>${marker.driverId}</div>
           </div>`,
            iconSize: [20, 20],
            popupAnchor: [0, -20]
        });
    }, [marker]);
    return (
        <Marker
            position={pick(marker, ['lat', 'lng'])}
            icon={icon}
        >
            <Popup>
                <b>{marker.driver}</b><br />
            </Popup>
        </Marker>
    );
};

const MapResize = ({ isMap, style }: any) => {
    const map = useMap();

    const handleResize = () => {
        console.log(map);
        if (style?.width && style?.height) {
            map.invalidateSize();
            map.flyTo(map.getCenter());
        }
    };


    useEffect(() => {
        if (!isMap) return;
        handleResize();
    }, [style, isMap]);

    return null;
};

const OrderMap = ({ style: _style }: any) => {
    const isMapOpen = useSelector(selectIsMapOpen);
    const mapState = useSelector(selectMapState);
    const [markers, setMarkers] = useState<any>( mapState ? Object.keys(mapState).map(key =>mapState[key]) : []);
    const mapRef = useRef<any>(null);
    const style = useRef({..._style} as any);
    const location = useLocation();
    const [isMapInLightMode, setIsMapInLightMode] = useState(true);
    const darkModeUrl = 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png';
    const lightModeUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    const time = useRef<null | NodeJS.Timer>(null);

    const checkMarkers = useCallback(() => {
        setMarkers(mapState ? Object.keys(mapState).filter(x => moment().diff(moment(mapState?.[x]?.lastTimeActive), 'seconds') < 25).map(key =>mapState[key]) : []);
    }, [mapState, setMarkers]);

    const changeMapTheme = useCallback(() => {
        setIsMapInLightMode(!isMapInLightMode);
    }, [setIsMapInLightMode, isMapInLightMode]);

    const isMap = useMemo(()=> Number(location.pathname === '/map'),[location]);

    useGetCurrentDriversQuery({active: true});

    const resizeMap = (mapRef: any) => {
        const resizeObserver = new ResizeObserver(() => mapRef.current?.invalidateSize());
        const container = document.getElementById('map-container');
        if (container) {
            resizeObserver.observe(container);
        }
    };

    const initialPosition = useMemo(() => ({ lat: 43.58226, lng: 21.32918 }), []);

    const resetMapPosition = () => {
        if (mapRef.current) {
            mapRef.current.setView(initialPosition, 14); // Reset to initial center and zoom level
        }
    };

    const handlerBeforeUnaload = () => {
        if(isMap) {
            localStorage.removeItem('mapOpen');
        }
    };

    useEffect(() => {
        time.current = setInterval(checkMarkers, 25000);
        return () => {
            if (time.current) {
                clearInterval(time.current);
            }
        };
    }, []);

    useEffect(() => {
        if(time.current) clearInterval(time.current);
        time.current = setInterval(checkMarkers, 25000);
        checkMarkers();
    }, [mapState]);


    useEffect(() => {
        if(mapRef.current) {
            mapRef.current.setView({
                lat: 43.58226,
                lng: 21.32918
            } as any, 14);
        }
    }, []);

    useEffect(()=> {
        if(!isMap) return;
        style.current = {
            width: `${window.innerWidth}px`,
            height: `${window.innerHeight}px`
        } as any;
    },[isMap]);

    useEffect(() => {
        window.addEventListener('beforeunload', handlerBeforeUnaload);
        return () => {
            window.removeEventListener('beforeunload', handlerBeforeUnaload);
        };
    }, []);

    if (isMapOpen && !style.current?.height) {
        return null;
    }

    return (
        <OrderViewMapContainer
            ref={mapRef}
            center={initialPosition}
            zoom={14}
            scrollWheelZoom={true}
            whenReady={() => resizeMap(mapRef)}
            dragging
            isMap={isMap}
        >
            <OrderViewMapBottomLeftActionsContainer>
                <OrderViewMapActionsButton title={'Promeni temu mape'} onClick={changeMapTheme}>
                    {isMapInLightMode ? <LightModeIcon /> : <DarkModeIcon />}
                </OrderViewMapActionsButton>
            </OrderViewMapBottomLeftActionsContainer>
            <OrderViewMapBottomRightActionsContainer>
                <OrderViewMapActionsButton title={'Centriraj mapu'} onClick={resetMapPosition}>
                    <MyLocationIcon />
                </OrderViewMapActionsButton>
            </OrderViewMapBottomRightActionsContainer>
            <MapResize isMap={isMapOpen}  style={style.current} />
            <TileLayer url={isMapInLightMode ? lightModeUrl : darkModeUrl} />
            {markers && markers.length ? markers.map((marker: any, index: number) => <CustomMarker key={index}
                                                                                      marker={marker} />) : null}
        </OrderViewMapContainer>
    );
};


export default OrderMap;