import { TUiReducer, TUiReducerAction, UI_EVENTS } from 'context/UiContext/d';
import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import UiDataContext from 'context/UiContext/context';
import { guid } from '../../utils';
import { MessageType, NotificationType } from '../../components/appbar/appbarRight/Notifications/d';
import { useAppDispatch } from '../../store/hooks';
import { setMapStateByDriver } from '../../store/app/appSlice';
import { omit } from 'lodash';
import { SHIFT_SCHEDULING_STATUS } from '../../views/Schedule/view/SchedulingBoard/d';


const reducer = (state: TUiReducer, action: TUiReducerAction) => {

    switch (action.type) {
        default:
            return state;

        case UI_EVENTS.UI_SET_LOADING: {
            const { timer, isSkeleton, text, isMini } = action.payload || {};
            if (typeof isMini !== 'undefined') return {
                ...state,
                loading: {
                    isLoading: false,
                    isMini
                }
            };
            if (typeof isSkeleton !== 'undefined') {
                return {
                    ...state,
                    loading: {
                        isLoading: false,
                        isSkeleton
                    }
                };
            }
            return {
                ...state,
                isLoading: true,
                timer: timer ? Number(timer) : void (0),
                isSkeleton: void (0),
                isMini: void (0),
                text
            };
        }

        case UI_EVENTS.UI_RESET_LOADING: {
            if (!state?.isLoading && !state?.timer && !state?.isSkeleton && !state?.isMini) return state;
            return {
                ...state,
                isLoading: false,
                timer: void (0),
                isSkeleton: false,
                isMini: false,
                text: void (0)
            };
        }

        case UI_EVENTS.WS_SET_SOCKET: {
            return {
                ...state,
                socket: action.payload
            };
        }

        case UI_EVENTS.UI_REFETCH_DRIVERS: {
            return {
                ...state,
                needDriverRefetch: new Date().getTime()
            };
        }


        case UI_EVENTS.UI_CLEAR_REFETCH_DRIVERS: {
            return {
                ...state,
                needDriverRefetch: 0
            };
        }

        case UI_EVENTS.WS_SET_MESSAGE: {
            return {
                ...state,
                messages: [...state.messages, {
                    guid: guid(),
                    ...action.payload
                }]
            };
        }

        case UI_EVENTS.WS_REMOVE_MESSAGE: {
            const index = [...state.messages].findIndex(x => x.guid === action.payload);
            if (index === -1) return state;
            const messages = [...state.messages];
            messages.splice(index, 1);
            return {
                ...state,
                messages
            };
        }

        case UI_EVENTS.WS_REMOVE_MESSAGE_BY_TYPE: {
            const data = [...state.messages].filter(x => x.type === action.payload);
            if (!data?.length) return state;
            const messages = [...state.messages].filter(x => x.type !== action.payload);
            return {
                ...state,
                messages
            };
        }


        case UI_EVENTS.WS_REMOVE_MESSAGE_BY_MULTI_TYPE: {
            const data = [...state.messages].filter(x => action.payload.includes(x.type));
            if (!data?.length) return state;
            const messages = [...state.messages].filter(x => !action.payload.includes(x.type));
            return {
                ...state,
                messages
            };
        }

        case UI_EVENTS.WS_REMOVE_MESSAGE_TYPE_ARRAY: {
            const data = [...state.messages].filter(x => [
                NotificationType.ORDER_FINISHED,
                NotificationType.ORDER_ITEM_FINISHED,
                NotificationType.ORDER_ABORTED,
                NotificationType.ORDER_CUSTOMER_NOT_SHOW
            ].includes(x.type));
            if (!data?.length) return state;
            const messages = [...state.messages].filter(x => ![
                NotificationType.ORDER_FINISHED,
                NotificationType.ORDER_ITEM_FINISHED,
                NotificationType.ORDER_ABORTED,
                NotificationType.ORDER_CUSTOMER_NOT_SHOW
            ].includes(x.type));
            return {
                ...state,
                messages
            };
        }

        case UI_EVENTS.WS_CLEAR_STATE: {
            return {
                ...state,
                messages: [],
                socket: undefined
            };
        }

    }
};


//const WS_URL = process.env.WS_URL as string;
//const WS_URL = 'ws://localhost:9821';
const WS_URL = 'wss://api.dev.dingdong.rs/ws';

const useUiContext = () => {
    const dataReducerStartState = useRef({
        isLoading: false,
        timer: void (0),
        isSkeleton: false,
        isMini: false,
        text: void (0),
        messages: [],
        needDriverRefetch: 0
    });
    const appDispatch = useAppDispatch();
    const [state, dispatch] = useReducer(reducer, dataReducerStartState.current as TUiReducer);

    const setLoading = useCallback((payload?: TUiReducer) => {
        dispatch({
            type: UI_EVENTS.UI_SET_LOADING,
            payload
        });
    }, [dispatch]);

    const resetLoading = useCallback(() => {
        dispatch({
            type: UI_EVENTS.UI_RESET_LOADING
        });
    }, [dispatch]);

    const wsRemoveMessage = useCallback((payload: string) => dispatch({
        type: UI_EVENTS.WS_REMOVE_MESSAGE,
        payload
    }), [dispatch]);

    const wsRemoveOrderMessages = useCallback(() => dispatch({
        type: UI_EVENTS.WS_REMOVE_MESSAGE_TYPE_ARRAY
    }), [dispatch]);

    const wsRemoveMessagesByMultiTypes = useCallback((payload: any) => dispatch({
        type: UI_EVENTS.WS_REMOVE_MESSAGE_BY_MULTI_TYPE,
        payload
    }), [dispatch]);

    const setRefetchDrivers = useCallback(() => dispatch({
        type: UI_EVENTS.UI_REFETCH_DRIVERS
    }), [dispatch]);

    const clearRefetchDrivers = useCallback(() => dispatch({
        type: UI_EVENTS.UI_CLEAR_REFETCH_DRIVERS
    }), [dispatch]);

    const wsRemoveMessagesByType = useCallback((payload: string) => dispatch({
        type: UI_EVENTS.WS_REMOVE_MESSAGE_BY_TYPE,
        payload
    }), [dispatch]);

    useEffect(() => {
        const ws = new WebSocket(WS_URL);


        ws.onmessage = (message) => {
            const messageData = JSON.parse(message?.data);
            if (messageData.eventType && messageData?.eventType === 'ADMIN') {

                (async () => {
                    let sound = '';
                    switch (messageData.type) {
                        case NotificationType.ORDER_FINISHED:
                            sound = 'order-finished.mp3';
                            break;
                        case NotificationType.ORDER_ITEM_FINISHED:
                            sound = 'order-item-finished.mp3';
                            break;
                        case NotificationType.ORDER_ABORTED:
                        case NotificationType.ORDER_CUSTOMER_NOT_SHOW:
                            sound = 'customer-not-show.mp3';
                            break;
                    }
                    if (sound) {
                        const audio = new Audio(`/sounds/${sound}`);
                        try {
                            await audio.play();
                        } catch (error) {
                            console.error('Error playing sound:', error);
                        }
                    }
                })();



                switch (messageData.type) {
                    case 'DRIVER_LOCATION':
                        appDispatch(setMapStateByDriver({
                            driverId: messageData?.data?.driverId,
                            data: omit(messageData?.data, ['driverId'])
                        }));
                        break;
                    default:
                        dispatch({
                            type: UI_EVENTS.WS_SET_MESSAGE,
                            payload: messageData
                        });
                        break;
                }
            }
        };

        ws.onclose = () => {
            dispatch({
                type: UI_EVENTS.WS_CLEAR_STATE
            });
        };

        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        dispatch({
            type: UI_EVENTS.WS_SET_SOCKET,
            payload: ws
        });

        const isTest = false;
        if (isTest) {
            const locations = [
                { lat: 43.582474, lng: 21.326973 },
                { lat: 43.581697, lng: 21.328025 },
                { lat: 43.581137, lng: 21.328990 },
                { lat: 43.580655, lng: 21.329741 },
                { lat: 43.580344, lng: 21.330321 },
                { lat: 43.579909, lng: 21.331072 },
                { lat: 43.579241, lng: 21.332123 },
                { lat: 43.578899, lng: 21.333625 },
                { lat: 43.578681, lng: 21.333631 },
                { lat: 43.578549, lng: 21.333835 },
                { lat: 43.578308, lng: 21.334081 },
                { lat: 43.577849, lng: 21.334623 },
                { lat: 43.577507, lng: 21.335031 },
                { lat: 43.577391, lng: 21.335181 },
                { lat: 43.577127, lng: 21.334956 },
                { lat: 43.576956, lng: 21.334516 },
                { lat: 43.576816, lng: 21.334194 },
                { lat: 43.576505, lng: 21.333797 },
                { lat: 43.576007, lng: 21.333314 },
                { lat: 43.575689, lng: 21.333121 }
            ];
            let i =0;
            setInterval(()=> {
                if (ws.readyState === WebSocket.OPEN) {
                    ws.send(JSON.stringify({
                        type: 'DRIVER_LOCATION',
                        data: {
                            ...locations?.[i],
                            driverId: 16
                        }
                    }));
                    if(i === locations.length - 1) {
                        i = 0;
                    } else {
                        i++;
                    }
                }
            },3500);
        }

        return () => {
            ws.close();
            dispatch({
                type: UI_EVENTS.WS_CLEAR_STATE
            });
        };
    }, []);


    return useMemo(() => ({
        ...state,
        shiftResume: state.messages.filter(x=> x.type === MessageType.SHIFT_STATUS_RESUME && x?.data?.status === SHIFT_SCHEDULING_STATUS.START),
        orderShiftMsg: state.messages.filter(x=> [NotificationType.ORDER_SAW].includes(x.type)).map(x=> x.id),
        orderMsgs: state.messages.filter(x=> [NotificationType.ORDER_ITEM_FINISHED, NotificationType.ORDER_CUSTOMER_NOT_SHOW, NotificationType.ORDER_ABORTED].includes(x.type)).map(x=> x.id),
        orderFinishedMsg: state.messages.filter(x=> x.type === NotificationType.ORDER_FINISHED).map(x=> x.data.id),
        shiftPause:  state.messages.filter(x=> x.type === MessageType.SHIFT_STATUS_PAUSE).map(x=> x?.data?.status && x?.data?.status == SHIFT_SCHEDULING_STATUS.PAUSE ? x?.data?.driverId : undefined).filter(x=> !!x),
        setLoading,
        resetLoading,
        wsRemoveMessage,
        wsRemoveMessagesByType,
        wsRemoveOrderMessages,
        setRefetchDrivers,
        clearRefetchDrivers,
        wsRemoveMessagesByMultiTypes
    }), [
        state,
        setLoading,
        resetLoading,
        wsRemoveMessage,
        wsRemoveMessagesByType,
        wsRemoveOrderMessages,
        setRefetchDrivers,
        clearRefetchDrivers,
        wsRemoveMessagesByMultiTypes
    ]);
};


export type TUiContextType = ReturnType<typeof useUiContext>;
const UiContextContainer = ({
                                children
                            }: any) => {

    const providerData = useUiContext();

    return (
        <UiDataContext.Provider value={providerData}>
            {children}
        </UiDataContext.Provider>
    );
};

export default UiContextContainer;
