import { useTranslation } from 'react-i18next';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import UiDataContext from '../../../../../context/UiContext/context';

import ShiftSchedulingForDayContext from '../DefineShiftsForDay/context/context';
import useCreateShiftsMutation from '../../../../../core/api/hooks/mutations/shiftScheduling/useCreateShiftsMutation';
import * as yup from 'yup';
import { Translate } from '../../../../../internationalization/translate';
import { Form, Formik } from 'formik';
import { DefineShiftForDayContent, DefineShiftsForDayContainer } from '../DefineShiftsForDay/style';
import ShiftForDayShiftsContent from '../DefineShiftsForDay/components/ShiftForDayShiftsContent';
import { format } from 'date-fns';
import { errorToast, successToast } from '../../../../../components/Utils/Toast';
import { useQueryClient } from '@tanstack/react-query';
import {
    DefineShiftDateHeader,
    DefineShiftForDayHeaderTitle,
    DefineShiftsForDayHeader
} from '../DefineShiftsForDay/components/ShiftForDayHeader/style';
import Translation from '../../../../../components/Translation';
import moment from 'moment';
import ShiftSchedulingForDateContextContainer from '../DefineShiftsForDay/context';
import useGetShiftSchedulingByDateTypeQuery
    from '../../../../../core/api/hooks/query/shiftScheduling/useGetShiftSchedulingByDateTypeQuery';
import useUpdateShiftsMutation from '../../../../../core/api/hooks/mutations/shiftScheduling/useUpdateShiftsMutation';
import { omit } from 'lodash';
import { formatCurrency } from '../../../../../utils';
import { useSelector } from 'react-redux';
import { selectSettingsShifts } from '../../../../../store/app/appSlice';


const DefineUpdateShiftForm = ({ closeModal, shiftDate, shiftType, status, isUpdate }: any) => {
    const { t } = useTranslation();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const createShiftScheduling = useCreateShiftsMutation();
    const updateShiftScheduling = useUpdateShiftsMutation();
    const { shifts, activeShift, setActiveShift, setShifts, resetShifts, needRefetch, clearNeedRefetch } = useContext(ShiftSchedulingForDayContext);

    const queryClient = useQueryClient();

    const settingsShift = useSelector(selectSettingsShifts);
    
    const isShiftStarted = useMemo(() => {
        const _settingsShift = settingsShift?.[`${activeShift}`];
        return moment(`${shiftDate} ${_settingsShift.from}`).isBefore(moment()) && ![6,5].includes(status);
    }, [shiftDate, activeShift, settingsShift, status]);
    

    const shiftSchema = yup.object().shape({});
    const [newValidationSchema, setNewValidationSchema] = useState(shiftSchema);

    useEffect(() => {
        setActiveShift(+shiftType);
    }, []);

    const { data, isLoading, isFetched } = useGetShiftSchedulingByDateTypeQuery({
        shiftDate,
        shiftType,
        isEnabled: !!isUpdate
    });

    const setShiftsData = useCallback((_data: any)=> {
        const _shifts = _data.map((x: any) => ({
            id: x.id,
            shiftId: shiftType,
            [`${shiftType}_vehicleId_${x.id}`]: x.vehicleId,
            [`${shiftType}_driverId_${x.id}`]: x.driverId,
            [`${shiftType}_amount_${x.id}`]: formatCurrency(x?.shiftStartAmount?.replace(/\./g, ',')),
            orders: x.orders,
            isUpdate: isUpdate && isShiftStarted,
            status: x.status
        }));
        setShifts({
            [shiftType]: _shifts
        });
    },[setShifts, shiftType, isUpdate, isShiftStarted]);

    useEffect(() => {
        if (!data || isLoading || !isFetched) {
            return;
        }
        setShiftsData(data);
    }, [data, isLoading, isFetched]);


    useEffect(() => {
        if (!shifts) return;
        const shiftsValidations = Object.values(shifts).map(x => x).flat().reduce((acc: any, x: any) => {
            const dd = !isUpdate ? {
                [`${x.shiftId}_vehicleId_${x.id}`]: yup.string().required(Translate.requiredField),
                [`${x.shiftId}_driverId_${x.id}`]: yup.string().required(Translate.requiredField),
                [`${x.shiftId}_amount_${x.id}`]: yup.string().required(Translate.requiredField)
            } : { [`${x.shiftId}_vehicleId_${x.id}`]: yup.string().required(Translate.requiredField) };
            return acc.concat(
                yup.object().shape(dd));
        }, yup.object().shape({}));
        setNewValidationSchema(shiftSchema.concat(shiftsValidations as any));
    }, [isUpdate, shifts]);


    const onSubmit = useCallback(async (values: any, {resetForm}: any) => {
        const newMap = new Map();
        Object.keys(values).forEach(x => {
            const [field, id] = (() => {
                const arr = x.split('_');
                return [arr[1], arr[2]];
            })();
            const [_field, value] = (() => {
                const isAmount = field === 'amount';
                const value = values[x];
                return [
                    !isAmount ? field : 'shiftStartAmount',
                    !isAmount ? value : Number(value?.replace(/\./gmi, '').replace(/,/gi, '.'))
                ];
            })();
            const valueMap = newMap.get(id);
            let obj = Object.assign(
                { [_field]: value },
                valueMap ? { ...valueMap } : {},
                isUpdate && Number(id) < 999999999 ? { id } : {}
            );
            if (isUpdate && isShiftStarted) {
                obj = { ...omit(obj, ['status']) };
            }
            newMap.set(id, obj);
        });
        const payloadData = Array.from(newMap.values());
        if (!payloadData?.length) {
            errorToast(t(Translate.schedulingShiftsNotAdded));
            return;
        }
        const payload = {
            shiftDate: format(shiftDate, 'yyyy-MM-dd'),
            data: payloadData,
            shiftType
        };
        try {
            const results = !isUpdate ? await createShiftScheduling.mutateAsync(payload) : await updateShiftScheduling.mutateAsync(payload);
            if (results?.status === 200) {
                successToast(t(results.message));
                resetShifts();
                resetForm();
                await queryClient.invalidateQueries({ queryKey: ['shift-scheduling'] });
                closeModal();
            }
        } catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        } finally {
            resetLoading();
        }
    }, [t, closeModal, shiftType, shiftDate, queryClient, setLoading, resetLoading, createShiftScheduling, isUpdate, updateShiftScheduling, isShiftStarted,  resetShifts]);


    useEffect(() => {
        if(!needRefetch) return;
        queryClient.invalidateQueries({ queryKey: [`shift-scheduling-by-date-type-${shiftDate}-${shiftType}`] }).then();
        clearNeedRefetch();
    }, [needRefetch, shiftDate, shiftType]);


    useEffect(() => {
        return () => {
            resetShifts();
        };
    }, []);

    return (
        <Formik
            initialValues={{}}
            validationSchema={newValidationSchema}
            onSubmit={onSubmit}
        >
            <Form>
                <DefineShiftsForDayContainer isNeedMaxWidth={1}>
                    <DefineShiftsForDayHeader>
                        <Translation use={Translate.defineShiftForDayTitle} as={DefineShiftForDayHeaderTitle} />
                        <DefineShiftDateHeader>{moment(shiftDate).format('DD.MM.YYYY')}</DefineShiftDateHeader>
                    </DefineShiftsForDayHeader>
                    <DefineShiftForDayContent>
                        <ShiftForDayShiftsContent closeModal={closeModal} isNeedMaxWidth={0}
                                                  isShiftStarted={isShiftStarted} isUpdate={isUpdate} />
                    </DefineShiftForDayContent>
                </DefineShiftsForDayContainer>
            </Form>
        </Formik>
    );
};

const DefineUpdateShift = ({ ...rest }: any) => {


    return (
        <ShiftSchedulingForDateContextContainer>
            <DefineUpdateShiftForm {...rest} />
        </ShiftSchedulingForDateContextContainer>
    );
};


export default DefineUpdateShift;